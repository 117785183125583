/*
 * @Author: ghh 277611282@qq.com
 * @Date: 2022-05-24 11:10:43
 * @LastEditors: ghh 277611282@qq.com
 * @LastEditTime: 2022-05-24 11:22:46
 * @FilePath: \piano-web\src\store\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    theme: true,
  },
  getters: {},
  mutations: {
    themeStyle(state, val) {
      state.theme = val;
    },
  },
  actions: {
    changeThemeStyle({ state, commit }, init) {
      commit("themeStyle", init);
    },
  },
  modules: {},
});
