import Vue from "vue";
import VueRouter from "vue-router";
const indexhome = (resolve) => require(["../views/index.vue"], resolve);
// const homePage = (resolve) =>
//   require(["../views/important/homePage.vue"], resolve);
//   require(["../views/important/jysDetail.vue"], resolve);

const webhomePage = (resolve) =>
  require(["../views/contentlist/index.vue"], resolve);
  // require(["../views/important/jysDetail.vue"], resolve);

const historyBid = (resolve) => require(["../views/contentlist/historybid.vue"], resolve);
const mobilebill = (resolve) => require(["../views/contentlist/mobilebill.vue"], resolve);

const login = (resolve) => require(["../views/login.vue"], resolve);


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: login,
  },
  {
    path: "/indexhome",
    name: "indexhome",
    component: indexhome,
    redirect: { name: "webhomePage" },
    children: [
      {
        path: "/webhomePage",
        name: "webhomePage",
        component: webhomePage,
        meta: { title: "线缆列表", keepAlive: false },
      },
      {
        path: "/historyBid",
        name: "historyBid",
        component: historyBid,
        meta: { title: "历史中标", keepAlive: false },
      },
      {
        path: "/mobilebill",
        name: "mobilebill",
        component: mobilebill,
        meta: { title: "产品报价单", keepAlive: false },
      }
    ],
  },
  {
    path: "/login",
    name: "login",
    component: login,
    meta: { title: "登录", keepAlive: true },
  },
];

const router = new VueRouter({
  routes,
});

export default router;
