import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/styles/app.less";
import "./assets/styles/theme.less";
import echarts from "echarts";

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import 'lib-flexible/flexible'
Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.prototype.$echarts = echarts;

Vue.prototype.$store = store;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
